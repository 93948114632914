<template>
  <vx-card title="แก้ไขข้อมูลบัญชี">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          class="w-full mt-4"
          v-validate="'required|numeric|max:20'"
          label="หมายเลขบัญชี"
          placeholder="กรุณาใส่หมายเลขบัญชี"
          v-model="new_data.bankno"
          name="bankno"
        />
        <span class="text-danger text-sm" v-show="errors.has('bankno')">{{
          errors.first("bankno")
        }}</span>
        <vs-input
          class="w-full mt-4"
          v-validate="'required|alpha_num|max:20'"
          label="Username"
          placeholder="กรุณาใส่ Username"
          v-model="new_data.username"
          name="username"
        />
        <span class="text-danger text-sm" v-show="errors.has('username')">{{
          errors.first("username")
        }}</span>
        <br />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          label="ชื่อบัญชี"
          placeholder="กรุณาใส่ชื่อบัญชี"
          v-model="new_data.fullname"
          name="fullname"
        />
        <span class="text-danger text-sm" v-show="errors.has('fullname')">{{
          errors.first("fullname")
        }}</span>
        <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          type="password"
          label="Password"
          placeholder="กรุณาใส่ Password"
          v-model="new_data.password"
          name="password"
        />
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">ประเภทธนาคาร</label>
        <v-select
          v-model="bank"
          :options="bankOptions"
          name="status"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">ประเภทบัญชีพัก</label>
        <v-select
          class="mt-1"
          v-model="bank_usage_type"
          :options="bank_usage_type_options"
          name="bank_usage_type"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>
      <div class="vx-col md:w-1/1 w-full">
      <vs-input
        v-if="bank.value === '000'"
        class="w-full mt-4"
        label-placeholder="deviceId"
        v-model="new_data.deviceId"
        name="deviceId"
      />
      </div>
      <div class="vx-col md:w-1/1 w-full">
      <vs-input
        v-if="bank.value === '004'"
        class="w-full mt-4"
        label-placeholder="K-bank code"
        v-model="new_data.code"
        name="code"
      />
      </div>
      <div class="vx-col md:w-1/1 w-full">
      <vs-input
        v-if="bank.value === '004' || bank.value === '000'"
        class="w-full mt-4"
        label-placeholder="Pin"
        v-model="new_data.pin"
        name="pin"
      />
      </div>
    </div>
    <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          label="รายละเอียด"
          placeholder="กรุณาใส่ descriptions"
          v-model="new_data.descriptions"
          name="descriptions"
        />
    <div class="mt-8 flex flex-wrap items-center justify-end">
      <vs-button color="success" class="ml-4 mt-2" @click="Save"
        >ยืนยัน</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import { Validator } from 'vee-validate'
import vSelect from 'vue-select'
import axios from '../../axios'

const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน20ตัว'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    fullname: {
      required: 'กรุณากรอกข้อมูล'
    },
    bankno: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    },
    phone: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status: false,
      bankfrom: this.$route.params.temporary,
      bankid: this.$route.params.id,
      databank: [],
      datagroups: [
        {
          text: 'บัญชีหลัก',
          value: 0
        },
        {
          text: 'บัญชีสำรอง',
          value: 1
        }
      ],
      new_data: {
        bankno: '',
        fullname: '',
        username: '',
        password: '',
        bankreserve: '',
        bank_code: '',
        descriptions:''
      },
      info_log: '',
      bankOptions: [
        {
          label: 'ไทยพาณิชย์ (SCB)',
          value: '000'
        },
        {
          label: 'กสิกรไทย (KBANK)',
          value: '004'
        },
        {
          label: 'กรุงไทย (KTB)',
          value: '006'
        },
        {
          label: 'กรุงศรีอยุธยา (BAY)',
          value: '025'
        },
        {
          label: 'เกียรตินาคิน (KKP)',
          value: '069'
        },
        {
          label: 'ออมสิน (GSB)',
          value: '030'
        },
        {
          label: 'ทหารไทยธนชาต',
          value: '011'
        },
        {
          label: 'กรุงเทพ',
          value: '002'
        },
        {
          label: 'ไอซีบีซี',
          value: '070'
        },
        {
          label: 'ไทยเครดิต',
          value: '071'
        },
        {
          label: 'ซิตี้แบงก์',
          value: '017'
        },
        {
          label: 'ซูมิโตโม มิตซุย',
          value: '018'
        },
        {
          label: 'สแตนดาร์ดชาร์เต',
          value: '020'
        },
        {
          label: 'ซีไอเอ็มบีไทย',
          value: '022'
        },
        {
          label: 'ยูโอบี',
          value: '024'
        },
        {
          label: 'เอชเอสบีซี',
          value: '031'
        },
        {
          label: 'มิซูโฮ',
          value: '039'
        },
        {
          label: 'ธกส.',
          value: '034'
        },
        {
          label: 'ธอส.',
          value: '033'
        },
        {
          label: 'แลนด์ แอนด์เฮ้าส์',
          value: '073'
        },
        {
          label: 'ทิสโก้',
          value: '067'
        },
        {
          label: 'อิสลาม',
          value: '066'
        }
      ],
      bank: '',
      bank_usage_type: '',
      bank_usage_type_options: [
        {
          label: 'บัญชีพัก (TEMP)',
          value: 'TEMP'
        },
        {
          label: 'บัญชีกดเงิน (ATM)',
          value: 'ATM'
        },
        {
          label: 'บัญชีเบิก (BILL)',
          value: 'BILL'
        },
        {
          label: 'บัญชีอื่นๆ (Other)',
          value: 'OTH'
        }

      ]
    }
  },
  async mounted () {
    await axios
      .get(`settingwallet/editbanktemporary/${this.$route.params.id}`)
      .then((response) => (this.databank = response.data))
    this.new_data.bankno = this.databank[0].bank_number
    this.new_data.fullname = this.databank[0].bank_fullname
    this.new_data.username = this.databank[0].bank_username
    this.new_data.password = this.databank[0].bank_password
    this.new_data.descriptions = this.databank[0].bank_descriptions
    for (const bank of this.bankOptions) {
      if (bank.value === this.databank[0].bank_code) {
        this.bank = bank
      }
    }
    for (const bank_usage_type of this.bank_usage_type_options) {
      if (bank_usage_type.value === this.databank[0].bank_usage_type) {
        this.bank_usage_type = bank_usage_type
      }
    }
  },
  methods: {
    async Save () {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.new_data.bank_code = this.bank.value
          this.new_data.bank_usage_type = this.bank_usage_type.value

          await axios
            .post('settingwallet/updatebanktemporary', {
              bankfrom: 'bank_temporary',
              bankid: this.bankid,
              bankno: this.new_data.bankno,
              fullname: this.new_data.fullname,
              username: this.new_data.username,
              password: this.new_data.password,
              bankreserve: this.new_data.bankreserve,
              bank_code: this.new_data.bank_code,
              bank_usage_type: this.new_data.bank_usage_type,
              descriptions: this.new_data.descriptions,
              deviceId : this.new_data.deviceId,
              pin : this.new_data.pin,
              code : this.new_data.code
            })
            .then((response) => (this.status = response.data))
          console.log('this.status', this.status)
          if (this.status.status === false) {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })
          } else {
            this.$vs.notify({
              time: 3000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: this.status.info
            })


            setTimeout(() => {
              this.$router.push('/SettingTemporary')
            }, 1500)
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })
        }
      })
    },
    async comparedata () {
      if (this.databank[0].bank_number !== this.new_data.bankno) {
        this.info_log += `เลขบัญชี :    ${this.databank[0].bank_number} > ${this.new_data.bankno} `
      }
      if (this.databank[0].bank_fullname !== this.new_data.fullname) {
        this.info_log += `ชื่อบัญชี :     ${this.databank[0].bank_fullname} > ${this.new_data.fullname} `
      }
      if (this.databank[0].bank_username !== this.new_data.username) {
        this.info_log += `username :   ${this.databank[0].bank_username} > ${this.new_data.username}`
      }
      if (this.databank[0].bank_password !== this.new_data.password) {
        this.info_log += ` password :  ${this.databank[0].bank_password} > ${this.new_data.password}`
      }
      if (this.databank[0].bank_code !== this.new_data.bank_code) {
        this.info_log += `ธนาคาร :  ${this.databank[0].bank_code} > ${this.new_data.bank_code} `
      }
      if (this.databank[0].bank_usage_type !== this.new_data.bank_usage_type) {
        this.info_log += `ประเภทบัญชีพัก :  ${this.databank[0].bank_usage_type} > ${this.new_data.bank_usage_type} `
      }
      if (this.databank[0].descriptions !== this.new_data.descriptions) {
        this.info_log += `ประเภทบัญชีพัก :  ${this.databank[0].bank_descriptions} > ${this.new_data.descriptions} `
      }
    }
  }
}
</script>
